import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

function AppointmentDetailsModel(
  { show, handleClose, appointmentItem, setOpenAppointmentUpdate , openAppointmentUpdate, handelClickCheckout },
  props
) {
  function formatDateToDayMonthDate(dateString) {
    // Create a Date object from the input dateString
    const date = new Date(dateString);

    // Define arrays for day and month names
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Get the day of the week and month from the Date object
    const dayOfWeek = daysOfWeek[date.getDay()];
    const month = months[date.getMonth()];

    // Get the day of the month
    const dayOfMonth = date.getDate();

    // Construct the formatted string
    const formattedDate = `${dayOfWeek}, ${month} ${dayOfMonth}`;

    return formattedDate;
  }

  return (
    <Modal
      show={show}
      {...props}
      aria-hidden="true"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
      dialogClassName="custom__modal"
      backdropClassName="modal-backdrop fade"
      style={{ height: "60%" }}
    >
      <Modal.Header className="modal-header modal-header1">
        <h1 className="modal-title fs-5" id="exampleModalToggleLabel2">
          Appointment Details
        </h1>
        <button type="button" className="btn-close" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body className="modal-body p-0 text-start p-3 pt-3">
        {/* <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            minWidth: "600px",
          }} */}
        {/* > */}
          <Typography id="modal-modal-title" variant="h6" component="h2"  >
            {appointmentItem?.service?.serviceName}
            <h5 className="hair_and_makeup_card_h5">
              {formatDateToDayMonthDate(appointmentItem?.date)}
            </h5>
            <p>
              {appointmentItem?.time} Duration{" "}
              {appointmentItem?.service?.durationTime}
            </p>

            {appointmentItem?.serviceType === 1 ||
            appointmentItem?.serviceType === 3 ? (
              <>
                <div>
                  <hr />
                  <p>
                    {appointmentItem?.service?.serviceName}{" "}
                    <span className="float-end">
                      ${appointmentItem?.service?.price}
                    </span>
                  </p>
                </div>
                {appointmentItem?.additionalService === 1 && (
                  <>
                    <hr />
                    <p>Additional Services</p>
                    {appointmentItem?.additionalServiceName &&
                      appointmentItem.additionalServiceName.map(
                        (service, index) => (
                          <span key={index}>
                            {service !==
                              appointmentItem?.service?.serviceName && (
                              <>
                                {service}{" "}
                                {index !==
                                  appointmentItem.additionalServiceName.length -
                                    2 && ", "}
                              </>
                            )}
                          </span>
                        )
                      )}
                    <br />
                    Total Amount:{" "}
                    <span style={{ textAlign: "right" }}>
                      ${appointmentItem?.additionalAmount}
                    </span>
                  </>
                )}
              </>
            ) : null}
            <hr />
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {appointmentItem?.noShow === true
              ? "No-show: fees not applied"
              : appointmentItem?.serviceType === 1 ||
                appointmentItem?.serviceType === 3
              ? appointmentItem.description
              : appointmentItem?.notes}
          </Typography>

          <Divider />

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {appointmentItem?.notes}
          </Typography>
          {appointmentItem?.serviceType === 1 ||
          appointmentItem?.serviceType === 3 ? (
            <Typography id="modal-modal-user" sx={{ mt: 2 }}>
              Customer Name: {appointmentItem?.user?.name}
              <br />
              Customer Mobile: {appointmentItem?.user?.mobile}
              <br />
              Customer Email: {appointmentItem?.user?.email}
            </Typography>
          ) : null}
          <Divider />
          <button
            onClick={() => {
              setOpenAppointmentUpdate(true);
              handleClose();
            }}
            className="button_a1 d-block mx-auto mt-2"
          >
            Edit Service
          </button>
        {/* </Box> */}
      </Modal.Body>
    </Modal>
  );
}

export default AppointmentDetailsModel;
