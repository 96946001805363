import { Box, Typography } from "@mui/material";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

function NoShowModel(
  { show, handleClose, appointmentNoShow, appointmentItem },
  props
) {
    
  return (
    <Modal
      show={show}
      {...props}
      aria-hidden="true"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
      dialogClassName="custom__modal"
      backdropClassName="modal-backdrop fade"
      style={{ height: "70%" }}
    >
      <Modal.Header className="modal-header modal-header1">
        <h1 className="modal-title fs-5" id="exampleModalToggleLabel2">
          No Show
        </h1>
        <button type="button" className="btn-close" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body className="modal-body p-0 text-start ps-3 pt-3">
        <Box
          sx={{
            position: "absolute",
            top: "60%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            minWidth: "657px",
            height: "518px",
            overflow: "scroll",
          }}
        >
          {/* <Typography id="modal-modal-title" variant="h6" component="h2"> */}
            
          <h2
              className="text-center  text-center"
              style={{ paddingTop: "60px", textAlign:"center !important" }}
            >
              Mark as no-show?
            </h2>
            <h5
              className="text-center px-5 "
              style={{ paddingTop: "20px" }}
            >
              This will charge your client 
              {" "}{appointmentItem?.additionalService === 1 ||
                      appointmentItem?.additionalService ===
                      2 ? (
                      <b>
                        ${appointmentItem?.additionalAmount * 0.50}
                      </b>
                    ) : (
                      <b>
                        $
                        {appointmentItem?.discountedPrice
                          ? appointmentItem?.discountedPrice
                          : appointmentItem?.service.price ||
                          appointmentItem?.service
                              .discountPrice * 0.50}
                      </b>
                    )} {" "}
              based on the <br />  price of the  original service booked .
            </h5>

            <center className="mt-4">
              <Button
                className="view_more_btn1"
                onClick={() => {
                  appointmentNoShow(appointmentItem._id, "1");
                }}
              >
                Charge
              </Button>{" "}
              &nbsp; &nbsp;
              {/* <Button
                className="book_now_btn1"
                onClick={() => handleClose(false)}
              >
                Don't Charge
              </Button> */}

              <Button
                className="view_more_btn1"
                onClick={() => {
                  appointmentNoShow(appointmentItem._id, "2");
                }}
              >
                Don't Charge
              </Button>{" "}
            </center>
          {/* </Typography> */}
        </Box>
      </Modal.Body>
    </Modal>
  );
}

export default NoShowModel;
