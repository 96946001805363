// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { de } from "date-fns/locale";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBKPyh37KoX7RW8Nhwl1GFOICaKbphjCpM",
  authDomain: "shoponestop-8db77.firebaseapp.com",
  projectId: "shoponestop-8db77",
  storageBucket: "shoponestop-8db77.appspot.com",
  messagingSenderId: "394916287628",
  appId: "1:394916287628:web:ab33893177bf6d776b97a9",
  measurementId: "G-2RS7B4K37B"
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebase);
export default firebase