const  getUserRole = () =>{
    const user = JSON.parse(localStorage.getItem("user"));
    if(user){

      let role;
      if (user.role === "business") {
        role = "business";
        
      } else{
        role ='user'
      }
      return role ?? 'user';
    }
   

  }
  export default getUserRole;