import React from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import product from "../../../assets/images/productNotFound.jpg";
import { PutAdminAPI } from "app/middleware";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useLoading } from "app/hooks/useLoader";
function SelectPaymentMethod(
  {
    show,
    handleClose,
    openCheckloutModel,
    appointmentItem,
    appointments,
    setAppointments,
    handleCheckoutappointment,
    setIsSelected,
    isSelected,
  },
  props
) {
  const handleClick = (option) => {
    setIsSelected(option === isSelected ? null : option);
  };
  const{toggleLoading} = useLoading();

  const handleCompleteAppointment = async (id, paymentType) => {
    try {
      const shouldComplete = await Swal.fire({
        title: 'Confirm Checkout',
        text: 'Are you sure you want to checkout?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, proceed!'
      });
  
      if (shouldComplete.isConfirmed) {
        const apiData = {
          id,
          isStatus: 4,
          paymentMethod:paymentType,
        };
       toggleLoading(true);
        const res = await PutAdminAPI(apiData, "business/appointments/isCompleteStatus");
        if (res.statusCode === 200) {
          toast.success(res.message);
          const filterData = appointments?.map((app) => {
            if (app._id === id) {
              return {
                ...app,
                isStatus: 4,
              };
            }
            return app;
          });
          setAppointments(filterData);
          handleClose();
       toggleLoading(false);

        }
      }
    } catch (error) {
      toast.error(error);
      toggleLoading(false);

    }finally{
      toggleLoading(false);

    }
  };

  return (
    <Modal
      show={show}
      {...props}
      aria-hidden="true"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
      dialogClassName="custom__modal"
      style={{ height: "70%" }}
    >
      <Modal.Header className="modal-header modal-header1">
        <h1 className="modal-title fs-5" id="exampleModalToggleLabel2">
          Select Payment Method
        </h1>
        <button type="button" className="btn-close" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body className="modal-body p-0">
        <article className="card2">
          <div className="container">
            <div className="card-body">
              <div className="payment-type">
                <div className="types flex2 justify-space-between">
                  <div
                    className={
                      isSelected === "PayByCard" ? "type selected2" : "type"
                    }
                    onClick={() => {
                      handleCompleteAppointment(appointmentItem._id, "PayByCard");
                      handleClick("PayByCard");
                    }}
                  >
                    <div className="logo">
                      <i className="far fa-credit-card" />
                    </div>
                    <div className="text">
                      <h4 className="card_details">Pay By Card</h4>
                    </div>
                  </div>
                  <div
                    className={isSelected === "App" ? "type selected2" : "type"}
                    onClick={() => {
                      handleCheckoutappointment();
                      handleClick("App");
                    }}
                  >
                    <div className="logo ">
                      <i className="fa-brands fa-app-store" />
                    </div>
                    <div className="text">
                      <h4 className="card_details">App</h4>
                    </div>
                  </div>
                  <div
                    className={
                      isSelected === "Cash" ? "type selected2" : "type"
                    }
                    onClick={() => {
                      handleCompleteAppointment(appointmentItem._id, "Cash");
                      handleClick("Cash");
                    }}
                  >
                    <div className="logo">
                      <i className="fa-solid fa-money-bills" />
                    </div>
                    <div className="text">
                      <h4 className="card_details">Cash</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
      </Modal.Body>
      <Modal.Footer className="modal-footer d-block"></Modal.Footer>
    </Modal>
  );
}

export default SelectPaymentMethod;
